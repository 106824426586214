@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url("./assets/fonts/OpenSansRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: local("Open Sans"), local("OpenSans"),
    url("./assets/fonts/OpenSansBold.ttf") format("truetype");
}

body {
  margin: 0;
}

body * {
  font-family: "Open Sans";
}
